import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { GlobalDataContext } from "../../context/context";
import {MdOutlineRoofing} from 'react-icons/md';

const IntroV2 = (props) => {
	const { rpdata } = useContext(GlobalDataContext);
        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="intro-area intro-area--top">
			  <div className="container">
			    <div className="intro-area-inner-2">
			      <div className="row justify-content-center">
			        <div className="col-lg-10">
			          <div className="section-title text-center">
			            <h6 className="sub-title double-line">{rpdata?.dbPrincipal?.name}</h6>
			            <h2 className="title">We Are Looking For Workers With Experience In Finishing (Concrete)</h2>
						{rpdata?.dbPrincipal?.phones.map((phone, index) => {
							return(
							<p key={index}>
								<a className="btn btn-border-black" href={`tel:+1${phone.phone}`}>Contact Us Now!!</a>
							</p>
								);
							})}
			          </div>
			        </div>
			      </div>
			      <div className="intro-footer bg-base">
			        <div className="row">
			          <div className="col-md-4">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
			                  <MdOutlineRoofing className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">Professionalism</h5>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="col-md-4">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
							<MdOutlineRoofing className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">Diligence</h5>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="col-md-4">
			            <div className="single-list-inner">
			              <div className="media">
			                <div className="media-left">
							<MdOutlineRoofing className="icons-intro"/>
			                </div>
			                <div className="media-body align-self-center">
			                  <h5 className="text-white">On Time</h5>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }

export default IntroV2